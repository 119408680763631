// src/components/Sidebar/Sidebar.js
import React from 'react';
import { FaUser } from 'react-icons/fa'; // Using react-icons for icons
import { FaCubes, FaArrowRightToBracket, FaRegChessQueen, FaGrip } from 'react-icons/fa6'; // Using react-icons for icons
import styles from './Sidebar.module.css';
import { useLocation } from 'react-router-dom';
 
const Sidebar = ({ menu, isLoggedIn, handleLogout }) => {
  

  const location = useLocation();

  return (
    <div className={`${styles.menu} ${menu ? styles.menuIsOpen : styles.menuIsClosed}`}>
      <div className={`${styles.userInfo} ${location.pathname === '/private/userPanel' ? styles.active : ''}`}>
        <FaCubes size={32} />
        <h3><a href="/private/userPanel">Painel</a></h3>
      </div>
      <div className={`${styles.userInfo} ${location.pathname === '/private/meus-dados' ? styles.active : ''}`}>
        <FaUser size={32} />
        <h3><a href="/private/meus-dados">Meus Dados</a></h3>
      </div>
      <div className={`${styles.userInfo} ${location.pathname === '/private/categories' ? styles.active : ''}`}>
        <FaGrip size={32} />
        <h3><a href="/private/categories">Minhas Categorias</a></h3>
      </div>
      <div className={`${styles.userInfo} ${location.pathname === '/private/subscription' ? styles.active : ''}`}>
        <FaRegChessQueen size={32} />
        <h3>Assinatura</h3>
      </div>
      {isLoggedIn != null ? (
        <div onClick={handleLogout} className={styles.userInfo}>
          <FaArrowRightToBracket size={32} />
          <h3>Logout</h3>
        </div>
      ) : null}
    </div>
  );
};

export default Sidebar;