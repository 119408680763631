import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Outlet } from 'react-router-dom';
import Home from './components/Home/home';
import Upload from './components/Upload/upload';
import Login from './components/Login/login';
import Register from './components/Register/register';
import UserPanel from './components/UserPanel/userPanel';
import MeusDados from './components/MeusDados/meusDados';
import Header from './components/Header/header';
import Footer from './components/Footer/footer';
import About from './components/About/about';
import Categories from './components/Categories/categories';
import ResetPassword from './components/ResetPassword/ResetPassword';
import { jwtDecode } from "jwt-decode";


function PrivateRoute({ children }) {
  const token = localStorage.getItem('token');

  if (!token) {
    console.log('não ta logado')
    window.location.href = '/';
    return null;
  }

  const tokenExpiration = jwtDecode(token).exp;
  const currentTimestamp = Date.now() / 1000; // Convert to seconds

  if (tokenExpiration < currentTimestamp) {
    console.log('Token expirou');
    localStorage.removeItem('token');
    window.location.href = '/';
    return null;
  }

  return children;
}

function App() {
  return (
    <Router>
    <Header />
      <div>
{/*         <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/register">Register</Link>
            </li>
            <li>
              <Link to="/private/upload">Upload</Link>
            </li>
          </ul>
        </nav>  
 */}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
 {/*          <Route path="/register" element={<Register />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/password-reset" element={<ResetPassword />} />
          <Route
            path="private/*"
            element={
              <PrivateRoute>
                <Outlet />
              </PrivateRoute>
            }
          >
              <Route path='meus-dados' element={<MeusDados />} />
             <Route path="userPanel" element={<UserPanel />} />
             <Route path="categories" element={<Categories />} />
          </Route>
        </Routes>
      </div>

      <Footer />
    </Router>
  );
}

export default App;