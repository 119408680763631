import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import styles from './ResetPassword.module.css';

function ResetPassword() {
  const [newPassword, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  const handleResetPassword = async () => {

    console.log('entrou')

    if (newPassword !== confirmPassword) {
      setMessage('As senhas não coincidem.');
      return;
    }

    try {
      const response = await axios.post('/api/change-password', { newPassword, token });
      setMessage('Senha alterada com sucesso');
      // handle response
    } catch (error) {
      console.error('Error during password reset:', error);
    }
  };

  return (
    <div className="container">
      <h1>Redefinir Senha</h1>
      <div className={styles.formReset}>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Nova senha"
          required
        />
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirmar nova senha"
          required
        />
        <button onClick={()=>handleResetPassword()}>Redefinir Senha</button>
      </div>
      {message && <p>{message}</p>}
    </div>
  );
}

export default ResetPassword;