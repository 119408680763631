import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './header.module.css';
import logo from '../../assets/imgs/logo.png'
import { FaTimes } from 'react-icons/fa';

const Header = () => {

  const [isOpen, setIsOpen] = useState(false);
  const isLoggedIn = localStorage.getItem('token');
  const location = useLocation();

  const handleLogout = async () => {
    try {
      localStorage.removeItem('token');
      if (location.pathname !== '/') {
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <header className={styles.header}>
      <div className={styles.left}>
        <a href='/' title='home'>
          <img src={logo} alt="Logo" />
        </a>
      </div>
      <div className={styles.center}>
        <p>BY FELIPE LOURENZI</p>
      </div>
      <div className={styles.right}>
        <Link to="/about" className={styles.demo}>
          Sobre
        </Link>
{/*         <Link to="/register" className={styles.demo}>
          Adquirir
        </Link> */}
{/*         <Link to="/plans" className={styles.sobre}>
          Planos
        </Link> */}
        {isLoggedIn != null ?
          <Link to="/private/userPanel" className={styles.login}>
            Minha Conta
          </Link>
          :
          <Link to="/login" className={styles.login}>
            Login
          </Link>
        }
        {isLoggedIn != null ?         
          <span onClick={handleLogout} className={styles.logout}>Logout</span>
          : null
        }
      </div>
      <button onClick={() => setIsOpen(!isOpen)} className={styles.hamburger}>☰</button>
      {isOpen && (
        <div className={styles.menu}>
           <button onClick={() => setIsOpen(false)}><FaTimes /></button>
          <Link to="/about" className={styles.demo}>
            Sobre
          </Link>
          <Link to="/register" className={styles.demo}>
            Adquirir
          </Link>
    {/*       <Link to="/plans" className={styles.sobre}>
            Planos
          </Link> */}
          {isLoggedIn != null ?
            <Link to="/private/userPanel" className={styles.login}>
              Minha Conta
            </Link>
            :
            <Link to="/login" className={styles.login}>
              Login
            </Link>
          }
          {isLoggedIn != null ?         
            <span onClick={handleLogout} className={styles.logout}>Logout</span>
            : null
          }
        </div>
      )}
    </header>
  );
};

export default Header;