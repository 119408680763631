import React from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';
import styles from './footer.module.css';
// import logo from '../path/to/your/logo.png'; // Importe o logo aqui

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.left}>
        <ul className={styles.navList}>
          <li className={styles.navItem}>
            <Link to="/">Home</Link>
          </li>
{/*           <li className={styles.navItem}>
            <Link to="/contact">Contato</Link>
          </li> */}
          <li className={styles.navItem}>
            <Link to="/about">Sobre</Link>
          </li>
{/*           <li className={styles.navItem}>
            <Link to="/register">Adquirir</Link>
          </li> */}
{/*           <li className={styles.navItem}>
            <Link to="/plans">Planos</Link>
          </li> */}
        </ul> 
      </div>
      <div className={styles.center}>
        <span>CNPJ: 53.811.878/0001-73</span>
        <br></br>
        <span>Todos os direitos reservados</span>
      </div>
      <div className={styles.right}>
        <a
          href="https://api.whatsapp.com/send?phone=5511963406466"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.whatsappButton}
        >
          <FaWhatsapp className={styles.whatsappIcon} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;