// src/components/Upload/upload.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Upload.module.css'; // Import the CSS module

function Upload({ fetchUserPhotosByCategory,categories  }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    category_id: '', // Novo atributo para selecionar a categoria
    data_limite: '',
    data_inicial: '',
    link: '',
    ativa: false,
  });

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFormDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const getUserIdFromLocalStorage = () => {
    // Replace this with the actual logic to get the user ID from local storage
    // For example, if the user ID is stored under the key 'userId':
    return localStorage.getItem('token');
  };

  const handleUpload = async () => {
    try {
      if (!selectedFile) {
        setMessage('Selecione um arquivo para fazer o upload.');
        return;
      }

      // Verifique se os campos obrigatórios estão preenchidos
      if (
        formData.category_id === '' ||
        formData.data_limite === '' ||
        formData.data_inicial === '' ||
        formData.link === ''
        
      ) {
        setMessage('Preencha todos os campos obrigatórios.');
        return;
      }

      formData.ativa = formData.ativa ? 1 : 0;

      const userId = getUserIdFromLocalStorage();
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      };

      const formDataToUpload = new FormData();
      formDataToUpload.append('image', selectedFile);

      // Anexe os campos de formulário à solicitação
      for (const key in formData) {
        formDataToUpload.append(key, formData[key]);
      }

      const response = await axios.post('/api/upload', formDataToUpload, config);
      setMessage(response.data.message);
      setSelectedFile(null);
      fetchUserPhotosByCategory();
    } catch (error) {
      console.error('Erro ao fazer o upload:', error);
      setMessage('Erro ao fazer o upload.');
    }
  };


  return (
    <div className={styles.uploadContainer}>
      <h2>Upload de Foto</h2>
      <input type="file" onChange={handleFileChange} className={styles.fileInput} />
      <select
        name="category_id"
        value={formData.category_id}
        onChange={handleFormDataChange}
        className={styles.categorySelect}
      >
        <option value="">Selecione uma Categoria</option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.category_name}
          </option>
        ))}
      </select>
      <input
        type="datetime-local"
        name="data_inicial"
        placeholder="Data Inicial"
        value={formData.data_inicial}
        onChange={handleFormDataChange}
        className={styles.input}
      />
      <input
        type="datetime-local"
        name="data_limite"
        placeholder="Data Limite"
        value={formData.data_limite}
        onChange={handleFormDataChange}
        className={styles.input}
      />
      <input
        type="text"
        name="link"
        placeholder="Link"
        value={formData.link}
        onChange={handleFormDataChange}
        className={styles.input}
      />
      <label>
        Exibir Foto
        <input
          type="checkbox"
          name="ativa"
          className={styles.ativaCheckbox}
          checked={formData.ativa}
          onChange={handleFormDataChange}
        />
      </label>
      <button onClick={handleUpload} className={styles.button}>
        Upload
      </button>
      <p className={styles.message}>{message}</p>
    </div>
  )
}

export default Upload;
