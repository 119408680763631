import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import styles from './login.module.css';
import { FaEnvelope, FaLock } from 'react-icons/fa';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!email || !password) {
      setMessage('Por favor, preencha todos os campos.');
      return; 
    }

    try {
      const response = await axios.post('/api/login', { email, password });
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        setMessage('Login bem-sucedido!');
        setEmail('');
        setPassword('');
        navigate('/private/userPanel');
      } else {
        setMessage('Credenciais inválidas. Verifique seu nome de usuário e senha.');
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      if (error.response && error.response.status === 403) {
        setMessage('Por favor, realize ou aguarde a aprovação do pagamento para acessar os recursos do Sphere Viewer, você receberá um email quando aprovado.');
      } else {
        setMessage('Erro ao fazer login.');
      }
    }
  };

  const handleForgotPassword = async () => {
    setLoading(true);
    setForgotPassword(true);
    try {
      const response = await axios.post('/api/password-reset', { email });
      // handle response
      setResetSuccess(true);
    } catch (error) {
      console.error('Erro durante o processo:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className={styles.loginWrapper}>
      <h1>Login</h1>
      <div className={styles.inputGroup}>
        <FaEnvelope className={styles.icon} />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
      </div>
      {!forgotPassword && (
        <div className={styles.inputGroup}>
          <FaLock className={styles.icon} />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Senha"
            required
          />
        </div>
      )}
      {!forgotPassword && (
        <button onClick={handleLogin} className={styles.forgotButton}>
          Login
        </button>
      )}
      <button type="button" className={styles.forgotPassword} onClick={handleForgotPassword} disabled={loading || resetSuccess}>
        Esqueci minha senha
      </button>
      <p className={styles.message}>{message}</p>
      </div>
    </div>
  );
}

export default Login;