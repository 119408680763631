import React, { useState, useEffect, useRef } from 'react';
import styles from './StoryHomeVersion.module.css'; // Import your styles
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; 
import { FaPlay, FaPause } from 'react-icons/fa'; // Import the icons


const StoryHomeVersion = ({ userHome, categoriesHome, photosHome, categoryDetailsHome }) => {
 
    const [activeStory, setActiveStory] = useState(null);
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const intervalRef = useRef(null);
    

    console.log('user', userHome);
    console.log('categories', categoriesHome);
    console.log('photos', photosHome);
    console.log('categoryDetails', categoryDetailsHome);


    useEffect(() => {
        // Pré-carregar todas as imagens
        const preloadImages = () => {
            categoriesHome.forEach(category => {
                const images = photosHome[category.id]?.filter(photo => photo.ativa === 1);
                images.forEach(image => {
                    const img = new Image();
                    img.src = `/photos/${userHome.id}/${image.image}`;
                });
            });
        };

        preloadImages();
    }, [categoriesHome, photosHome, userHome.id]);
    

    useEffect(() => {
        if (activeStory && !isPaused) {
            setProgress(0);
            intervalRef.current = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress < 100) {
                        return prevProgress + 1; // Increase by 1% every 50 milliseconds
                    } else {
                        setActiveImageIndex((prevIndex) => {
                            if (prevIndex < activeStory.images.length - 1) {
                                return prevIndex + 1;
                            } else {
                                setActiveStory(null);
                                return 0;
                            }
                        });
                        return 0;
                    }
                });
            }, 50); // Change this to 50 milliseconds
        }
    
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [activeStory, activeImageIndex, isPaused]);

    const handleStoryClick = (category) => {
        if (categoryDetailsHome[category.id]?.is_active === 1) { // Check if the category is active
            setActiveStory({
                ...category,
                images: photosHome[category.id]?.filter(photo => photo.ativa === 1) // Filter only active photos
            });
            setActiveImageIndex(0);
        }
    };

    const handleImageClick = () => {
        setIsPaused(!isPaused);
    };

    const handleNextClick = () => {
        setActiveImageIndex((prevIndex) => {
            if (prevIndex < activeStory.images.length - 1) {
                return prevIndex + 1;
            } else {
                setActiveStory(null);
                return 0;
            }
        });
        setProgress(0);
    };

    const handlePrevClick = () => {
        setActiveImageIndex((prevIndex) => {
            if (prevIndex > 0) {
                return prevIndex - 1;
            } else {
                setActiveStory(null);
                return activeStory.images.length - 1;
            }
        });
        setProgress(0);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.className === styles.modal) {
                setActiveStory(null);
            }
        };

        const handleEscKey = (event) => {
            if (event.key === 'Escape') {
                setActiveStory(null);
            }
        };

        window.addEventListener('click', handleClickOutside);
        window.addEventListener('keydown', handleEscKey);

        return () => {
            window.removeEventListener('click', handleClickOutside);
            window.removeEventListener('keydown', handleEscKey);
        };
    }, []);

        
    const handleAcessar = () => {
        window.open(activeStory.images[activeImageIndex]?.link, '_blank');
    };

    return (
        <div className={styles.storyContainer}>
            {categoriesHome.map((category) => {
                const coverImageId = categoryDetailsHome[category.id]?.cover_image_id;
                const coverImagePath = photosHome[category.id]?.find(photo => photo.id === coverImageId)?.image;

                return (
                    categoryDetailsHome[category.id]?.is_active === 1 && // Check if the category is active
                    <div className={styles.categoryStory} key={category.id} onClick={() => handleStoryClick(category)}>
                        <img src={`/photos/${userHome.id}/${coverImagePath}`} alt={category.category_name} className={styles.categoryImage} />
                        <div>{category.category_name}</div>
                    </div>
                );
            })}
            {activeStory && (
                <div className={styles.modal}>
                    <div className={styles.progressBarContainer}>
                        {activeStory.images.map((image, index) => (
                            <div key={index} className={styles.progressBar}>
                                <div
                                    className={styles.progressBarFill}
                                    style={{ width: `${index === activeImageIndex ? progress : index < activeImageIndex ? 100 : 0}%` }}
                                ></div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.controls}>
                        <div className={styles.playPauseButton} onClick={handleImageClick}>
                            {isPaused ? <FaPlay className={styles.playIcon} /> : <FaPause className={styles.playIcon} />}
                        </div>
                    </div>
                    <div className={styles.controls}>
                        <div className={styles.acessarButton} onClick={handleAcessar}>
                            <span>Acessar</span>
                        </div>
                    </div>
                    <img
                        src={`/photos/${userHome.id}/${activeStory.images[activeImageIndex]?.image}`}
                        alt="story"
                        onClick={handleImageClick}
                        className={styles.image}
                    />
                    <div className={styles.controls}>
                        <FaArrowLeft onClick={handlePrevClick} className={styles.controlIcon} />
                        <FaArrowRight onClick={handleNextClick} className={styles.controlIcon} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default StoryHomeVersion;