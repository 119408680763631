import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Categories.module.css';
import { useLocation } from 'react-router-dom';
import Sidebar from '../Common/Sidebar/sidebar';


function Categories() {
  const [categories, setCategories] = useState([]);
  const [menu, setMenu] = useState(true);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [editCategoryName, setEditCategoryName] = useState('');
  const [editingCategory, setEditingCategory] = useState(null);
  const [creatingCategory, setCreatingCategory] = useState(false);


  // Fetch categories when the component mounts
  useEffect(() => {
    fetchCategories();
  }, []);

  const isLoggedIn = localStorage.getItem('token');
  const location = useLocation();

  const handleLogout = async () => {
    try {
      // Remove the token from local storage
      localStorage.removeItem('token');

      // Redirect to the homepage only if not already on the homepage
      if (location.pathname !== '/') {
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };



  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${isLoggedIn}`,
    },
  };

  


  // Fetch categories from the server
  const fetchCategories = async () => {
    try {
      const response = await axios.get('/api/categories', config); // Replace with your API endpoint
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };


  // Create a new category
  const createCategory = async () => {
    if (newCategoryName == '') {
      return; // Evite chamadas duplicadas ou vazias
    }

    try {
      setCreatingCategory(true); // Marque a criação da categoria como em andamento
      const formData = new FormData();

      console.log(newCategoryName)
      formData.append('category_name', newCategoryName);

      await axios.post('/api/categories', formData, config);
      fetchCategories(); // Atualize a lista de categorias
    } catch (error) {
      console.error('Error creating category:', error);
    } finally {      
      setNewCategoryName(''); // Limpe o input
      setCreatingCategory(false); // Marque a criação da categoria como concluída, bem-sucedida ou malsucedida
       }
  };

  // Update the name of a category
  const updateCategory = async (categoryId) => {
    if (editCategoryName=='') {
      return; // Evite chamadas duplicadas enquanto a requisição estiver em andamento
    }
    try {
      setEditingCategory(true); // Marque a atualização da categoria como em andamento
      const formData = new FormData();  
      
      console.log(editCategoryName)
      formData.append('category_name', editCategoryName);      
      await axios.put(`/api/categories/${categoryId}`, formData, config); 
      fetchCategories(); // Atualize a lista de categorias
    } catch (error) {
      console.error('Error updating category:', error);
    } finally {
      setEditingCategory(false); // Marque a atualização da categoria como concluída, bem-sucedida ou malsucedida      
      setEditCategoryName(''); // Limpe o input
    }
  };

  // Delete a category
  const deleteCategory = async (categoryId) => {
    try {
      await axios.delete(`/api/categories/${categoryId}`, config); // Replace with your API endpoint
      fetchCategories(); // Refresh the category list
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  return (
    <div className={styles.categories}>
      <Sidebar menu={menu} isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
      <div className={`${styles.content} ${menu ? styles.contentZ : styles.contentFull}`}>
        <h1>Minhas Categorias</h1>
        <div className={styles.creatingCategory}> 
          <input
            type="text"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            placeholder="Nome da Categoria"
          />
          <button className={styles.creatingCategoryButton} onClick={createCategory}>Criar</button>
        </div>
        <ul>
          {categories.map((category) => (
            <li key={category.id} className={styles.categoryItem}>
              {editingCategory === category.id ? (
                <div className={styles.editCategoryNameSection}>
                  <input
                    type="text"
                    value={editCategoryName}
                    placeholder='Novo nome'
                    onChange={(e) => setEditCategoryName(e.target.value)}
                  />
                  <button className={styles.editCategory} onClick={() => updateCategory(category.id)}>Salvar</button>
                </div>
              ) : (
                <div className={styles.nameSection}>Nome: {category.category_name}</div>
              )}
              <div>
                <button className={styles.editCategory} onClick={() => setEditingCategory(category.id)}>Editar</button>
                <button className={styles.deleteCategory} onClick={() => deleteCategory(category.id)}>Deletar</button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Categories;
